import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import a3 from "../../Assets/Projects/a3.svg";
import demesix from "../../Assets/Projects/demesix.png";
import odoor from "../../Assets/Projects/odoor.jpg";
import inditex from "../../Assets/Projects/inditex.png";
import pipop from "../../Assets/Projects/pipop.jpeg";
import olympic from "../../Assets/Projects/olympic.svg";
import mercadona from "../../Assets/Projects/mercadona.svg";


import { useTranslation } from 'react-i18next';

function Projects() {
  const { t } = useTranslation();
  const sizeXS = 12;
  const sizeSM = 12;
  const sizeM = 12;
  const sizeL = 6;
  const sizeXL = 4;


  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">{t('works')} </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col xs={sizeXS} sm={sizeSM} md={sizeM} lg={sizeL} xl={sizeXL} className="project-card">
            <ProjectCard
              imgPath={mercadona}
              title="Mercadona"
              description={t('mercadona')}
              link="https://www.mercadona.es/"
            />
          </Col>

          <Col xs={sizeXS} sm={sizeSM} md={sizeM} lg={sizeL} xl={sizeXL} className="project-card">
            <ProjectCard
              imgPath={olympic}
              title="Olympic Channel"
              description={t('olympic')}
              link="https://olympics.com/"
            />
          </Col>

          <Col xs={sizeXS} sm={sizeSM} md={sizeM} lg={sizeL} xl={sizeXL} className="project-card">
            <ProjectCard
              imgPath={a3}
              title="Atresmedia Player"
              description={t('a3')}
              link="https://www.atresplayer.com/"
            />
          </Col>

          <Col xs={sizeXS} sm={sizeSM} md={sizeM} lg={sizeL} xl={sizeXL} className="project-card">
            <ProjectCard
              imgPath={inditex}
              title="Inditex"
              description={t('inditex')}
              link="https://www.inditex.com/itxcomweb/es/home"
            />
          </Col>

          <Col xs={sizeXS} sm={sizeSM} md={sizeM} lg={sizeL} xl={sizeXL} className="project-card">
            <ProjectCard
              imgPath={odoor}
              title="Odoor"
              description={t('odoor')}
              link="https://odoor.com/"
            />
          </Col>

          <Col xs={sizeXS} sm={sizeSM} md={sizeM} lg={sizeL} xl={sizeXL} className="project-card">
            <ProjectCard
              imgPath={demesix}
              title="Demesix"
              description= {t('demesix')}
              link="http://www.demesix.com/"
              customImageClass="custom-image"
            />
          </Col>

          <Col xs={sizeXS} sm={sizeSM} md={sizeM} lg={sizeL} xl={sizeXL} className="project-card">
            <ProjectCard
              imgPath={pipop}
              title="Pipop"
              description={t('pipop')}
              link="http://pipop.es/"

            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
