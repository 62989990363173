import React from "react";
import Typewriter from "typewriter-effect";
import { useTranslation } from 'react-i18next';

function Type() {
    const { t } = useTranslation();

  return (
    <Typewriter
      options={{
        strings: [
          t('freelance'),
          t('android_engineer'),
          t('full_stack'),
          t('software_engineer'),
          t('team_management'),
          t('natural_leader'),
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
