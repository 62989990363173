import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap'; 
import { CgWebsite } from 'react-icons/cg';

function ProjectCards(props) {
  const { customImageClass } = props;

  return (
    <Container>
      <Row>
        <Col> 
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <Card.Img className={`project-card-image ${customImageClass ? customImageClass : ''}`} variant="top" src={props.imgPath} alt="card-img" style={{ height: "300px", width: "400px" }} />
              </div>
              <div className="flip-card-back">
                <Card className="project-card-view transparent-bg">
                  <Card.Body className="project-card-body d-flex flex-column">
                    <Card.Title className="project-card-text-color">{props.title}</Card.Title>
                    <Card.Text style={{ textAlign: "justify", paddingTop: "20px", whiteSpace: "pre-wrap", marginBottom: "10px" }}>
                      {props.description}
                    </Card.Text>
                    <Button variant="primary" href={props.link} className="project-card-button mt-auto" target="_blank">
                      <CgWebsite /> &nbsp;
                      {"Website"}
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ProjectCards;

