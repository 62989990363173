import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from 'react-i18next';

function AboutCard() {
  const { t } = useTranslation();

  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {t('about_me_text_1')}<span className="purple"> {t('my_name')} </span>
            {t('from')} <span className="purple">{t('location')}</span>
            <br />
            {t('about_me_text_2')}
            <br />
            <br />
            {t('about_me_text_3')}   
            <br />   
            <br />  
            {t('about_me_text_4')}          
            </p>
          <p className="quote-color">
            "{t('solutions')}"{" "}
          </p>
          <footer className="blockquote-footer">{t('my_name')}</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
